<template>
  <div class="subscription-canceled-page">
    <div class="readable_content">
      <h4>Your membership has been cancelled.</h4>
      <p v-if="!deactivated">You can still enjoy the benefits of your membership until {{ expireDate }}.</p>
      <p>
        If you change your mind, you can always re-activate your membership via your
        <router-link :to="accountPageRoute">Account page</router-link>.
      </p>
      <p><router-link :to="homePageRoute">&larr; Back to Home</router-link></p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  computed: {
    expireDate() {
      return moment(decodeURIComponent(this.$route.query.expire_on)).format('MMMM DD, YYYY');
    },
    deactivated() {
      return this.$route.query.deactivated;
    },
    accountPageRoute() {
      return {name: 'user-profile'};
    },
    homePageRoute() {
      return {name: 'main-page'};
    },
  },
  name: 'SubscriptionCanceledPage',
};
</script>

<style scoped></style>
